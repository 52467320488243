<template>
  <BTableCard
    :searchActive="true"
    :newAddActive="false"
    :totalActive="true"
    :backActive="backTo"
    :show="show"
    :icon="'fad fa-user-headset'"
    :title="pageTitle"
    :total="total"
    :filtered="filtered"
  >
    <template v-slot:headerAction>
      <div>
        
        <b-button
          squared
          variant="success"
          v-if="!backTo"
          @click="handlerAddNew"
          v-b-tooltip.hover="'Yeni Kayıt'"
        >
          <i class="fad fa-plus" /> YENİ KAYIT
        </b-button>
        <b-button
          squared
          variant="secondary"
          v-else
          @click="handlerBackTo"
          v-b-tooltip.hover="'Geri'"
        >
          <i class="fad fa-angle-left" /> GERİ
        </b-button>
        </b-button-group>
      </div>
    </template>
    <template v-slot:body>
      <transition mode="out-in">
        <router-view
          @total="total = $event"
          :filtered="filtered"
          @filtered="handlerFiltered($event)"
          @show="show = $event"
          @backTo="backTo = $event"
          @pageTitle="pageTitle = $event"
        />
      </transition>
    </template>
  </BTableCard>
</template>
<script>
import store from "@/store";
import { useRouter } from "@/libs/utils";
import BTableCard from "@/components/cards/BTableCard.vue";
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  components: {
    BTableCard,
  },
  setup() {
    const expo = {};
    const { route, router } = useRouter();
    expo.show = ref(false);
    expo.filtered = ref(false);
    expo.backTo = ref(false);
    expo.total = ref(0);
    expo.pageTitle = ref("Destek Hattı");

    expo.handlerAddNew = () =>{}
    expo.handlerBackTo = () =>{}

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
