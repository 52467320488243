<template>
  <div class="h-100 w-100">
    <BListCard
      :searchActive="false"
      :newAddRightActive="true"
      :totalActive="false"
      :backToPage="true"
      :show="show"
      :title="`${title}`"
      :total="total"
      :leftStyle="'width: 25%'"
      @pageToPage="handlerPageToPage"
      @newAdd="handlerNewAdd"
    >
      <template v-slot:leftBody>
        <ul class="menu-list-group">
          <li class="menu-list-group-item" v-for="(item, index) in menus" :key="index">
            <router-link :to="{ name: item.route }">
              <i v-if="item.icon" :class="`fad fa-${item.icon}`" />
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </template>
      <template v-slot:rightBody>
        <div>
          <transition :name="transitionEffect" mode="out-in">
            <router-view @show="show = $event" @title="title = $event" @total="total = $event" />
          </transition>
        </div>
      </template>
    </BListCard>
  </div>
</template>

<script>
import BListCard from '@/components/cards/BListCard.vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useRouter } from '@/libs/utils';
import store from '@/store';
export default defineComponent({
  components: {
    BListCard,
  },
  setup() {
    const expo = {};
    const { route, router } = useRouter();
    expo.show = ref(false);

    expo.title = ref(null);
    expo.total = ref(0);

    expo.menus = ref([
      {
        title: 'Acenteler',
        route: 'acente-listele',
        icon: 'bars',
      },
      {
        title: 'Kullanıcılar',
        route: 'acente-kullanici-listele',
        icon: 'users',
      },
      {
        title: 'Kullanıcı Yetkileri',
        route: 'acente-kullanici-yetki-listele',
        icon: 'eye',
      },
    ]);

    expo.transitionEffect = computed(() => store.getters.getTransitionEffect);

    const handlerFetchData = async () => {
      await store.dispatch('kurAyarlariGetir');
    };

    handlerFetchData();

    expo.handlerNewAdd = () => {
      if (route.value.name == 'acente-listele' || route.value.name == 'acente-guncelle') {
        router.push({ name: 'acente-ekle' });
      } else if (route.value.name == 'acente-kullanici-listele' || route.value.name == 'acente-kullanici-guncelle') {
        router.push({ name: 'acente-kullanici-ekle' });
      } else if (route.value.name == 'acente-kullanici-yetki-listele' || route.value.name == 'acente-kullanici-yetki-guncelle') {
        router.push({ name: 'acente-kullanici-yetki-ekle' });
      }
    };

    expo.handlerPageToPage = () => {
      if (route.value.name == 'acente-ekle' || route.value.name == 'acente-guncelle' || route.value.name == 'acente-detail') {
        router.push({ name: 'acente-listele' });
      } else {
        router.go(-1);
      }
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.menu-list-group {
  padding: 10px;
  border-radius: 0;
  .menu-list-group-item {
    position: relative;
    border: none;
    padding: 0px;
    margin: 5px;
    display: block;
    justify-content: space-between;
    align-items: center;
    a {
      color: #4d4d4d;
      font-size: 18px;
      font-weight: 500;
      display: block;
      padding: 15px 10px;
      transition: all 0.2s ease-in-out;
      i {
        padding-right: 10px;
      }
      &:hover {
        transition: all 0.2s ease-in-out;
        background-color: rgba(0, 0, 0, 0.03);
      }
      &.router-link-active {
        transition: all 0.2s ease-in-out;
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}
</style>
